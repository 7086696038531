<template>
    <!-- eslint-disable vue/no-v-model-argument -->
    <main class="grid">
        <!-- LOADER -->
        <loading v-model:active="loader.isLoading" :can-cancel="false" :is-full-page="true" color="#007680" :width="loader.loaderSize" :height="loader.loaderSize" :opacity="loader.opacity" />
        <!-- LOGO -->
        <!-- <div class="col-12 md:col-10 lg:col-10 xl:col-10 m-auto">
            <div class="card flex m-auto">
                <img class="col-6 m-auto sm:col-4 md:col-4 lg:col-4 xl:col-2" :src="logo" alt="Logo del centro" />
            </div>
        </div> -->

        <!-- STUDENT'S INDEX -->
        <!-- <div v-if="userMode == 'student'" class="col-12 md:col-10 lg:col-10 xl:col-10 m-auto"> -->
        <!-- CENTER'S ROOMS -->
        <!-- <div class="card">
                <DataTable id="rooms-table" :value="centerRooms" data-key="id" :paginator="true" :rows="10" responsive-layout="scroll">
                    <template #header>
                        <div class="flex flex-column sm:flex-row sm:text-center md:justify-content-start md:align-items-start">
                            <h3 class="mr-auto ml-auto mt-auto lg:m-3 xs:mb-2">{{ $t("endpoints.rooms.card.header_student") }}</h3>
                        </div>
                    </template>
                    <Column>
                        <template #body="centerRooms"> <Button type="button" class="p-button-success p-button-rounded m-auto flex" icon="pi pi-eye" @click="gomedia($event, centerRooms.data)"></Button> </template>
                    </Column>
                    <Column field="name" :header="$t('endpoints.rooms.table.columns.name')" :sortable="true"></Column>
                    <Column field="owner.self" :header="$t('endpoints.rooms.table.columns.subject')" :sortable="true"></Column> -->
        <!-- <Column field="room_date" :header="$t('endpoints.rooms.table.columns.date')" :sortable="true"> </Column> -->
        <!-- </DataTable>
            </div>
        </div> -->

        <!-- TEACHER'S INDEX -->
        <div v-show="!loader.isLoading" v-if="userMode == 'teacher'" class="col-12 md:col-10 lg:col-10 xl:col-10 m-auto">
            <!-- <div class="col-12 md:col-11 lg:col-11 xl:col-10 m-auto" v-if="this.userMode == 'teacher'"> -->
            <div class="card">
                <Toast id="session-toast" />

                <!-- USER'S SESSIONS -->
                <DataTable id="sessions-table" ref="dt" :value="userSessions.filter((session) => session.status)" data-key="id" :paginator="true" :rows="10" responsive-layout="scroll">
                    <template #header>
                        <div class="flex flex-column sm:flex-row sm:text-center md:justify-content-start md:align-items-start">
                            <h3 class="mr-auto ml-auto mt-auto lg:m-3 xs:mb-2">{{ $t("endpoints.rooms.table.header_teacher") }}</h3>
                            <Button id="create-session" :label="$t('endpoints.rooms.card.button')" icon="pi pi-plus" class="p-button-center m-auto lg:mr-2 md:mr-2 sm:mr-2 p-4 lg:ml-auto md:ml-auto sm:ml-auto" style="font-size: 1.2rem" @click="openNew()" />
                        </div>
                    </template>
                    <Column>
                        <template #body="userSessions">
                            <!-- <Button v-if="userMode == 'teacher'" v-tooltip.focus.bottom="{ value: 'Code copied to clipboard' }" icon="pi pi-copy" class="p-button-rounded p-button-info mt-2 lg:ml-0 xl:ml-3" :aria-label="$t('accesibility.rooms.copy')" @click="copyCode($event, userSessions.data)" /> -->
                            <a :href="`https://meet.bemyvega.dev/${userSessions.data.session_id}`" target="_blank">
                                <Button icon="pi pi-eye" class="p-button-rounded p-button-success mt-2 lg:ml-0 xl:ml-3" :aria-label="$t('accesibility.rooms.go')" />
                            </a>

                            <Button v-if="userMode == 'teacher'" icon="pi pi-trash" class="p-button-rounded p-button-danger mt-2 lg:ml-0 xl:ml-3" :aria-label="$t('accesibility.rooms.delete')" @click="openConfirmation(userSessions.data)" />
                            <Dialog v-model:visible="displayConfirmation" header="Confirmation" :style="{}" :modal="true">
                                <div class="flex align-items-center justify-content-center">
                                    <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
                                    <span>{{ $t("endpoints.rooms.card.confirmation") }}</span>
                                </div>
                                <template #footer>
                                    <Button label="No" icon="pi pi-times" class="p-button-text" @click="closeConfirmation" />
                                    <Button label="Yes" icon="pi pi-check" class="p-button-text" autofocus @click="deleteSession" />
                                </template>
                            </Dialog>
                        </template>
                    </Column>
                    <Column field="session_name" :header="$t('endpoints.rooms.table.columns.name')" :sortable="true"></Column>
                    <Column field="private" header="Privada" :sortable="true"></Column>
                    <Column field="ip_cameras" header="Cámaras IP" :sortable="true"></Column>
                    <!-- <Column field="owner.self" :header="$t('endpoints.rooms.table.columns.subject')" :sortable="true"></Column> -->
                    <!-- <Column field="date" :header="$t('endpoints.rooms.table.columns.date')" :sortable="true"></Column> -->
                </DataTable>

                <!-- CREATE SESSION DIALOG -->
                <Dialog id="create-session-dialog" v-model:visible="sessionDialog" :style="{ width: '450px' }" :header="$t('endpoints.rooms.card.dialog.header')" :modal="true" class="p-fluid">
                    <!-- CREATE SESSION FORM -->
                    <form>
                        <!-- SESSION NAME -->
                        <div class="field">
                            <label for="sessionName">{{ $t("endpoints.rooms.card.dialog.name") }}</label>
                            <InputText id="sessionName" v-model="sessionName" type="text" />
                        </div>

                        <!-- SESSION IP CAMERAS -->
                        <div class="field">
                            <label for="sessionIpCameras">Cámaras IP</label>
                            <InputText id="sessionIpCameras" v-model="sessionIpCameras" type="text" />
                        </div>

                        <!-- SESSION PRIVATE -->
                        <div class="field">
                            <label for="sessionPrivate">Privada</label>
                            <Dropdown v-model="sessionPrivate" :options="optionsPrivate" option-label="label" placeholder="Selecciona una opción"></Dropdown>
                        </div>

                        <!-- USER'S CATEGORIES -->
                        <!-- <div class="field">
                            <label for="category">{{ $t("endpoints.rooms.card.dialog.subject.header") }}</label>
                            <Dropdown id="dropdown-categories" v-model="selectedCategory" :options="userCategories" option-label="name" :placeholder="$t('endpoints.rooms.card.dialog.subject.placeholder')" />
                        </div> -->
                    </form>

                    <!-- FOOTER BUTTONS -->
                    <template #footer>
                        <Button id="footer-cancel-session" :label="$t('endpoints.rooms.card.dialog.buttons.cancel')" icon="pi pi-times" class="p-button-text" @click="hideDialog" />
                        <Button id="footer-create-session" :label="$t('endpoints.rooms.card.dialog.buttons.save')" icon="pi pi-check" class="p-button-text" @click="createSession" />
                    </template>
                </Dialog>
            </div>
        </div>
    </main>
</template>

<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

// import { env_config } from "../../config";
import { getUserInfo } from "../utils/auth.js";
// import { getId } from "../utils/utils.js";
// import { RepositoryFactory } from "../repositories/RepositoryFactory";
import { mapState } from "vuex";
import axios from "axios";

// const CentersRepository = RepositoryFactory.get("centers");
// const UsersRepository = RepositoryFactory.get("users");
// const RoomsRepository = RepositoryFactory.get("rooms");

export default {
    components: {
        Loading
    },
    data() {
        let { logo } = getUserInfo();
        return {
            userMode: null,
            sessionName: null,
            sessionIpCameras: null,
            sessionPrivate: null,
            userSessions: [],
            centerSessions: [],
            centerCategories: [],
            userCategories: [],
            selectedCategory: null,
            sessionDialog: false,
            logo: logo,
            displayConfirmation: false,
            sessionToDelete: null,
            optionsPrivate: [
                { label: "Sí", value: "true" },
                { label: "No", value: "false" }
            ]
        };
    },
    computed: {
        ...mapState(["loader"])
    },
    created() {
        document.title = this.$t("accesibility.title.rooms");
        let { user_mode } = getUserInfo();
        this.userMode = user_mode;
        // if (this.userMode == "teacher") {
        this.getUserSessions();
        // } else {
        //     this.getCenterRooms();
        // }
    },
    methods: {
        async getUserSessions() {
            let { user_id } = getUserInfo();
            try {
                axios.get(`https://4q0847t4m8.execute-api.eu-west-1.amazonaws.com/prod/sessions?user_id=${user_id}`).then((response) => {
                    this.userSessions = response.data.data.items;
                    console.log(this.userSessions);
                });
            } catch (error) {
                console.log(error);
            }

            // let response;
            //
            // response = await UsersRepository.getRooms(user_id);
            // this.userRooms = response.data.results;
            //
            // response = await UsersRepository.getCategories(user_id);
            // this.userCategories = response.data.results;
            //
            // for (var key = 0; key < this.userRooms.length; key++) {
            //     this.userRooms[key]["link"] = `rtmp://webrtc.${env_config.client_name}.bemyvega.com:1935/${getId(this.userRooms[key].self)}`;
            //     for (var key1 = 0; key1 < this.userCategories.length; key1++) {
            //         if (this.userRooms[key].owner.self == this.userCategories[key1].self) {
            //             this.userRooms[key].owner.self = this.userCategories[key1].name;
            //         }
            //     }
            // }
        },
        // async getCenterRooms() {
        //     let { center_id } = getUserInfo();
        //     let response;

        //     response = await CentersRepository.getRooms(center_id);
        //     this.centerRooms = response.data.results;

        //     response = await CentersRepository.getCategories(center_id);
        //     this.centerCategories = response.data.results;

        //     for (var key = 0; key < this.centerCategories.length; key++) {
        //         for (var key1 = 0; key1 < this.centerRooms.length; key1++) {
        //             if (this.centerRooms[key1].owner.self == this.centerCategories[key].self) {
        //                 this.centerRooms[key1].owner.self = this.centerCategories[key].name;
        //             }
        //         }
        //     }
        // },
        async createSession() {
            let { user_id } = getUserInfo();

            const formData = {
                session_name: this.sessionName,
                ip_cameras: this.sessionIpCameras,
                private: this.sessionPrivate == "true" ? true : false,
                user_id: user_id
                // category_id: getId(this.selectedCategory.self)
            };
            try {
                await axios
                    .post(`https://4q0847t4m8.execute-api.eu-west-1.amazonaws.com/prod/sessions`, formData, {
                        headers: {
                            "Content-Type": "application/json"
                        }
                    })
                    .then((response) => {
                        console.log(response);
                    });
                // await RoomsRepository.createRoom(formData);
                this.getUserSessions();
                this.showSuccess("Session created successfully");
                this.hideDialog();
            } catch (error) {
                // let detail = error.response.data.detail;
                // detail = detail.charAt(0).toUpperCase() + detail.slice(1); // Capitalize the first letter of a string
                this.showError("error");
                console.log(error);
            }
        },
        async deleteSession() {
            try {
                await axios.delete(`https://4q0847t4m8.execute-api.eu-west-1.amazonaws.com/prod/sessions/${this.sessionToDelete}`).then((response) => {
                    console.log(response);
                });

                // let { data } = await RoomsRepository.deleteRoom(getId(this.roomToDelete));
                this.getUserSessions();
                this.showSuccess("Success");
                this.closeConfirmation();
                this.sessionToDelete = null;
            } catch (error) {
                console.log(error);
            }
        },
        openConfirmation(session) {
            this.displayConfirmation = true;
            this.sessionToDelete = session.session_id;
            console.log(session);
        },
        closeConfirmation() {
            this.displayConfirmation = false;
        },
        // goToMeetSession(event, session) {
        //     // this.$store.commit("confirm");
        //     // this.$router.push({ path: `/stream/${getId(room.self)}`, query: { roomName: room.name } });
        //     // event.preventDefault();
        //   k
        // },
        showSuccess(detail) {
            this.$toast.add({ severity: "success", summary: "Success", detail: detail, life: 3000 });
        },
        showError(detail) {
            this.$toast.add({ severity: "error", summary: "Error", detail: detail, life: 3000 });
        },
        toggle(event) {
            this.$refs.op.toggle(event);
        },
        openNew() {
            this.sessionDialog = true;
        },
        hideDialog() {
            this.sessionDialog = false;
        }

        // async copyCode(event, data) {
        //     await navigator.clipboard.writeText(getId(data.self));
        //     event.preventDefault();
        // }
    }
};
</script>

<style lang="scss" scoped>
@import "../assets/demo/badges.scss";
@import "../assets/demo/items.scss";

.p-button-center {
    background-color: --primary-color;
}
</style>
